import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Validators from "@/components/common/validations/Validator";
import useHandlePostAPI from "@/hooks/useHandlePostAPI";
import { toaster } from "@/services/helper";
import moment from "moment";
import DatePickerUi from "../forms/DatePickerUi";
import { apiConfig } from "@/configs";
import Textarea from "../forms/Textarea";
import Textinput from "../forms/TextInput";
import ReactModal from "../forms/modal/ReactModal";
import { Container } from "@assets/styles/common/global";
import { ReviewStyle } from "@assets/styles/pages/ReviewStyle";
import { GeneralContext } from "@/pages/core/layout";
import ReactButton from "../forms/ReactButton";
import { RegisterStyle } from "@assets/styles/pages/login/RegisterStyle";

const rules = {
	email: "required|email",
	lastname: "required",
	message: "required",
	name: "required",
	date: "required",
	phone: "required|phone|size:10",
};

export default function ReviewSection() {
	const { color } = useContext(GeneralContext);
	const [isOpen, setIsOpen] = useState(false);

	const initialState = {
		email: "",
		lastname: "",
		message: "",
		name: "",
		date: moment().format("DD-MM-YYYY"),
		phone: "",
	};
	const [state, setState] = useState({ ...initialState });

	let { formState, isLoader, callAPI, onChange, validationErrors } = useHandlePostAPI({
		populateFormData: state,
		defaultFormState: {
			email: "",
			lastname: "",
			message: "",
			name: "",
			date: moment().format("DD-MM-YYYY"),
			phone: "",
		},
		endpoints: apiConfig.patient.add_review,
		preparePayload: (formData) => {
			return {
				...formData,
				date: moment(formData?.date, "DD-MM-YYYY").format("YYYY-MM-DD"),
			};
		},
		callback: () => {
			setIsOpen(false);
			toaster.success("Add Review Successfully.");
		},
	});

	useEffect(() => {
		if (!isOpen) {
			setState({ ...initialState });
		}
	}, [isOpen]);

	return (
		<ReviewStyle color={color}>
			<div className="review--section">
				<Container>
					<Row>
						<Col md={10} xl={6} className="mx-auto text-center">
							<div className="review-title--box">
								<h6 className="font-20 lh-base res-font-18 crete mb-4">
									Your Review Can Help Us Improve And Provide Better Care For You And Others. Please Take A Moment To Share Your Experience.
								</h6>
								<ReactButton onClick={() => setIsOpen(true)} className="font-12 lh-1 spacing-12 open-sans fw-semibold px-4 text-uppercase">
									Review
								</ReactButton>
							</div>
						</Col>
					</Row>
				</Container>

				<Validators formData={formState} rules={rules} setErrors={validationErrors}>
					{({ onSubmit, errors }) => {
						return (
							<ReactModal
								isLoader={isLoader}
								title="Review Form"
								show={isOpen}
								btnTitle={"Submit"}
								className="review-modal"
								onSave={() => {
									onSubmit(callAPI);
								}}
								onHide={() => setIsOpen(false)}
							>
								<Container className="px-0 px-xl-5">
									<RegisterStyle color={color}>
										<form>
											<Row className="pt-4">
												<Col xl={6}>
													<Textinput
														name={"name"}
														type="text"
														error={errors?.name}
														value={formState?.name}
														placeholder="Enter First Name"
														onChange={onChange}
														tabIndex={1}
														className="w-100 mb-2 px-3 form-data border-0 radius-8 font-15 open-sans text-capitalize"
														mainclassName="pb-3"
													/>
												</Col>
												<Col xxl={6}>
													<Textinput
														type="text"
														name={"lastname"}
														error={errors?.lastname}
														value={formState?.lastname}
														onChange={onChange}
														className="w-100 mb-2 px-3 form-data border-0 radius-8 font-15 open-sans text-capitalize"
														mainclassName="pb-3"
														placeholder="Enter Last Name"
														tabIndex={2}
													/>
												</Col>
												<Col xl={6}>
													<Textinput
														type="email"
														name={"email"}
														error={errors?.email}
														value={formState?.email}
														onChange={onChange}
														className="w-100 mb-2 px-3 form-data border-0 radius-8 font-15 open-sans"
														mainclassName="pb-3"
														placeholder="Enter Email"
														tabIndex={3}
													/>
												</Col>

												<Col xl={6}>
													<Textinput
														type="number"
														name={"phone"}
														error={errors?.phone}
														value={formState?.phone}
														onChange={onChange}
														className="w-100 mb-2 px-3 form-data border-0 radius-8 font-15 open-sans text-capitalize"
														mainclassName="pb-3"
														placeholder="Enter Phone Number"
														tabIndex={4}
													/>
												</Col>
												<Col xxl={12} className="visitor--input-box">
													<DatePickerUi
														placeholdertext="Enter Date"
														tabIndex={5}
														value={formState.date}
														onChange={onChange}
														className="w-100 mb-2 px-3 form-data border-0 radius-8 font-15 open-sans text-capitalize"
														mainclassName="w-100 pb-3"
														name={"date"}
														onKeyDownAllow={false}
													/>
												</Col>

												<Col xl={12}>
													<Textarea
														type="text"
														name={"message"}
														error={errors?.message}
														value={formState?.message}
														onChange={onChange}
														rows={4}
														tabIndex={6}
														className="w-100 border-0 form-control radius-8 px-3 font-15 open-sans text-capitalize"
														mainclassName="pb-3"
														placeholder="Enter Message"
													/>
												</Col>
											</Row>
										</form>
									</RegisterStyle>
								</Container>
							</ReactModal>
						);
					}}
				</Validators>
			</div>
		</ReviewStyle>
	);
}
