const appConfig = {
	host: process.env.REACT_APP_HOST_API,
	prefix: process.env.REACT_APP_API_PREFIX,
	version: process.env.REACT_APP_API_VERSION,
	patient_login_url: process.env.REACT_APP_PATIENT_LOGIN,
	login_url: process.env.REACT_APP_LOGIN_URL,

	dateDisplayFormat: "DD/MM/YYYY",
	dateDisplayEditFormat: "YYYY-MM-DD",
	dateAndTimeDisplayFormat: "DD/MM/YYYY HH:mm A",
	defaultPerPage: 9999999,
	localStorage: {
		token: "hospital-bearer-token", // for saving bearer token
		role: "hospital-user-role",
	},

	persistKey: {
		authReducer: "auth",
	},

	default_pagination_state: {
		page: 1,
		rowsPerPage: 10,
	},
	perPageArray: [
		{
			label: "5",
			value: "5",
		},
		{
			label: "10",
			value: "10",
		},
		{
			label: "15",
			value: "15",
		},
		{
			label: "20",
			value: "20",
		},
		{
			label: "25",
			value: "25",
		},
	],

	defaultShowDateFormat: "MMMM DD, YYYY",
	defaultThemeColor: "#1370B5",
};

export default appConfig;
