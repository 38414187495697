import { GeneralContext } from "@/pages/core/layout";
import { MainButton } from "@assets/styles/ui/common/button";
import React, { useContext } from "react";
import { Button, Spinner } from "react-bootstrap";

const ReactButton = ({ backgroundcolor, onClick, otherClass = "", loader, children, className = "", size = "", variant = "", ...rest }) => {
	const { color } = useContext(GeneralContext);

	return (
		<MainButton color={color} className={otherClass}>
			<button
				size={size}
				onClick={onClick}
				variant={variant}
				className={`text-nowrap ${className}`}
				disabled={loader ? true : false}
				{...rest}
			>
				{loader && <Spinner size="sm" className="me-2"></Spinner>}
				<span className="btn-title d-flex align-items-center">{children}</span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
			</button>
		</MainButton>
	);
};

export default ReactButton;
