import { getImageUrl, isEmpty } from "@/services/helper";

const ReactImage = ({ src, className = "", id, image, style, onClick }) => {
	return (
		<>
			<img
				id={id}
				src={isEmpty(src) ? image : getImageUrl(src)}
				className={className}
				alt="..."
				// onError={({ currentTarget }) => {
				// 	currentTarget.onerror = null;
				// 	currentTarget.src = image;
				// }}
				style={style}
				onClick={onClick}
			/>
		</>
	);
};

export default ReactImage;
