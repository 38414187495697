import React from "react";
import { Route, Routes } from "react-router-dom";
import { webRoutes } from "./webRoutes";
import { filterRoute } from "@/configs/routes";
import VisitorLayout from "@/pages/core/layout";
import "../../assets/styles/global.css";
import "bootstrap/dist/css/bootstrap.min.css";
import PageNotFound from "@/components/errors/PageNotFound";
import Maintenance from "@/pages/core/Maintenance";

export default function InitialRoutes() {
	return (
		<Routes>
			<Route path="*" element={<PageNotFound />} />
			<Route path="/under-maintenance" element={<Maintenance />} />
			<Route path="/*" element={<VisitorLayout />}>
				{webRoutes.visitor.map(({ element: Component, ...route }, i) => (
					<Route key={i} path={filterRoute(route.path)} element={<Component />} />
				))}
			</Route>
		</Routes>
	);
}
