import styled from "styled-components";

export const ModalStyle = styled.div`
  div {
    .modal-header {
      color: ${(props) => props.color.backGroundColor};

      .visitor--btn-close {
        background-color: ${(props) => props.color.backGroundColor};
        color: ${(props) => props.color.fontColor};
      }
    }
  }
  .modal {
    .modal-content {
      position: relative;
    }

    &.review-modal {
      background-color: #f5f5f5 !important;
    }

    &.login-modal {
      .modal-dialog {
        .modla-content {
          .modal-body {
            padding: 20px 48px !important;
          }
        }
      }
    }

    &.appointment-modal {
      .modal-dialog {
        .modla-content {
          .modal-header {
            h4 {
              background: ${(props) => props.color.backGroundColor};
            }
          }
        }
      }
    }
  }
`;
