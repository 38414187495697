import React from "react";
import { Container } from "@assets/styles/common/global";
import { themeConfig } from "@/configs";
import ReactImage from "../ui/ReactImage";

export default function PageNotFound() {
	return (
		<Container fluid className="w-100 not--found--page--wrapper">
			<div className="image-wrapper">
				<ReactImage image={themeConfig.errorImage} className="h-100 w-100" />
			</div>
		</Container>
	);
}
