import serviceBackground from "@assets/images/servicebg.png";
import doctorBackground from "@assets/images/doctorbg.png";
import featureCardBackground from "@assets/images/featureCardBackground.png";
import appointmentBackground from "@assets/images/appointmentBackground.jpg";
import appointmentCounterBackground from "@assets/images/appointmentcounterBackground.png";
import doctorImage from "@assets/images/doctor.png";
import noSliderOrBanner from "@assets/images/noSliderImage.png";
import featuresImage from "@assets/images/featuresImage.png";
import plusIcon from "@assets/images/plusIcon.png";
import footerBg from "@assets/images/footerbg.jpg";
import blogBg from "@assets/images/blogbg.png";
import gallaryImage from "@assets/images/gallaryImage.png";
import serviceImage from "@assets/images/serviceImage.png";
import blogImages from "@assets/images/blogImages.png";
import defaultIamge from "@assets/images/default-image.png";
import aboutUsImage from "@assets/images/aboutUsImage.png";
import counterImage from "@assets/images/counterImage.png";
import doctorDetailImage from "@assets/images/DoctorDetalImage.png";
import blogDetailImage from "@assets/images/blogDeatilImage.png";
import emptyImage from "@assets/images/empty.svg";
import doctorImages from "@assets/images/doctor-image.png";
import serviceDetailImage from "@assets/images/serviceDetailImage.png";
import testimonialImage from "@assets/images/tesimonial_image.png";
import breadcumbImage from "@assets/images/breadcumbImage.jpg";
import BloodPressure from "@assets/images/report-icon-03.svg";
import Temperature from "@assets/images/report-icon-02.svg";
import patientDash from "@assets/images/morning-img-03.png";
import userImage from "@assets/images/user_.jpg";
import noDataImage from "@assets/images/noDataImage.jpg";
import errorImage from "@assets/images/error-404.png";
import UnderMaintanance from "@assets/images/under-maintenance.jpg";
import Maintanance from "@assets/images/maintananace.jpg";
import LogoImage from "@assets/images/default-logo.png";
import FooterLogo from "@assets/images/default-footer-logo.png";

const themeConfig = {
	patientDash,
	Temperature,
	BloodPressure,
	serviceBackground,
	doctorBackground,
	featureCardBackground,
	appointmentBackground,
	appointmentCounterBackground,
	doctorImage,
	noSliderOrBanner,
	featuresImage,
	plusIcon,
	footerBg,
	blogBg,
	gallaryImage,
	serviceImage,
	blogImages,
	defaultIamge,
	aboutUsImage,
	counterImage,
	doctorDetailImage,
	blogDetailImage,
	emptyImage,
	doctorImages,
	serviceDetailImage,
	testimonialImage,
	breadcumbImage,
	userImage,
	noDataImage,
	errorImage,
	UnderMaintanance,
	Maintanance,
	LogoImage,
	FooterLogo,
};

export default themeConfig;
