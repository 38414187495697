import { api, helper } from ".";
import { apiConfig } from "../configs";
import { store } from "../store";
import { setHeaders, setSettings, setTimetables } from "../store/toolkit/general";

export const initialApiCalls = () => {
	getSettings();
	getHeaders();
	getTimeTables();
};

const getSettings = () => {
	api
		.get(apiConfig.public.settings)
		.then((res) => {
			store.dispatch(setSettings(res));
		})
		.catch();
};

const getHeaders = () => {
	api
		.get(`${apiConfig.public.header}?is_active=${true}`)
		.then((res) => {
			store.dispatch(setHeaders(res));
		})
		.catch();
};

const getTimeTables = () => {
	api
		.get(apiConfig.public.home.time_table)
		.then((res) => {
			const timeTables = res.rows.map((item, i) => {
				item.day = item.from_day && item.to_day ? `${item.to_day} - ${item.from_day}` : item.to_day || item.from_day;
				if (item.from_evening && item.to_evening && item.from_morning && item.to_morning) {
					item.time = `<div key="${i}">
				<span>${item.from_morning} to ${item.to_morning}</span> - <span>${item.from_evening} to ${item.to_evening}</span>
				</div>`;
				} else if (!item.from_evening && !item.to_evening) {
					item.time = `<span>${item.from_morning} to ${item.to_morning}</span>`;
				} else if (!item.from_morning && !item.to_morning) {
					item.time = `<span>${item.from_evening} to ${item.to_evening}</span>`;
				} else {
					item.time = `<span>-</span>`;
				}
				return item;
			});
			store.dispatch(setTimetables(timeTables));
		})
		.catch();
};
