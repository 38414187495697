import moment from "moment";
import React from "react";
import ValidationMessages from "../common/validations/ValidationMessages";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DatePickerUi = ({
	error,
	onChange,
	label,
	name,
	value,
	lable,
	fRef,
	className = "d-block w-100 font14",
	classLabel = "",
	mainclassName = "",
	selected,
	onKeyDownAllow,
	tabIndex,
	minDate,
	onKeyDown,
	minDates,
	...rest
}) => {
	return (
		<div className={`dynamic--date-picker ${mainclassName}`}>
			{label && <label className={`date--picker-title text-capitalize ${classLabel}`}>{label}</label>}
			{/* <input
				style={{
					cursor: "pointer",
				}}
				ref={fRef}
				type="date"
				name={name}
				value={value}
				className={`date--picker-input is-invalid ${className}`}
				min={onKeyDownAllow == false ? "" : moment().format("YYYY-MM-DD")}
				max="9999-12-31"
				onChange={onChange}
				tabIndex={tabIndex}
				{...rest}
				maxLength={8}
				onKeyDown={
					onKeyDownAllow == false
						? (e) => {
								return e;
						  }
						: (e) => {
								e.preventDefault();
						  }
				}
			/> */}

			<ReactDatePicker
				tabIndex={tabIndex}
				className={`date--picker-input w-100 is-invalid ${className}`}
				minDate={minDates}
				value={value}
				onChange={(e) => {
					onChange({
						target: {
							name: name,
							value: moment(e).format("DD-MM-YYYY"),
						},
					});
				}}
			/>

			<ValidationMessages errors={error} name={name} label={label} />
		</div>
	);
};

export default DatePickerUi;
