import styled from "styled-components";

export const BackTopStyle = styled.div`
  .top--icon-wrap {
    display: block;
    position: fixed;
    bottom: 3%;
    right: 3%;
    z-index: 99;
    .top--icon {
      border-radius: 50%;
      border: 2px solid ${(props) => props.color.backGroundColor};
      box-sizing: content-box;
      height: 40px;
      padding: 0;
      position: relative;
      background-color: #fff;
      width: 40px;
      animation: scaleWarning 0.75s infinite alternate;

      @media (max-width: 768px) {
        width: 35px;
        height: 35px;
      }

      &:after,
      &:before {
        content: "";
        border-radius: 50%;
        height: 100%;
        position: absolute;
        width: 100%;
      }

      &:before {
        display: inline-block;
        opacity: 0;
        animation: pulseWarning 2s linear infinite;
      }

      &:after {
        display: block;
        z-index: 1;
      }

      svg {
        width: 100%;
        height: 100%;
        path {
          fill: ${(props) => props.color.backGroundColor};
        }
      }
    }
  }
  @keyframes scaleWarning {
    0% {
      transform: scale(1);
    }
    30% {
      transform: scale(1.02);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes pulseWarning {
    0% {
      background-color: ${(props) => props.color.fontColor};
      transform: scale(1);
      opacity: 0.5;
    }
    30% {
      background-color: ${(props) => props.color.fontColor};
      transform: scale(1);
      opacity: 0.5;
    }
    100% {
      background-color: ${(props) => props.color.backGroundColor};
      transform: scale(2);
      opacity: 0;
    }
  }
  @keyframes pulseWarningIns {
    0% {
      background-color: ${(props) => props.color.backGroundColor};
    }
    100% {
      background-color: ${(props) => props.color.backGroundColor};
    }
  }
`;
