import _, { isArray } from "lodash";
import { toast } from "react-hot-toast";
import { appConfig } from "../configs";
import { store } from "../store";
import Swal from "sweetalert2";
import { combinationColors } from "@/constant/data";
import moment from "moment";
import { helper } from ".";

export const isEmpty = (value) => {
	if (value == null || value === "null") {
		return true;
	}
	if (typeof value == "object") {
		return Object.keys(value).length === 0;
	}
	return (isArray(value) && value.length === 0) || value === undefined || value === "undefined" || value == null || value === "";
};

export const prepateSelectDropdown = (array, field1 = null, field2) => {
	if (isEmpty(array)) {
		return [];
	}
	return array
		.map((item) => {
			return {
				label: isEmpty(field1) ? item : item[field1],
				value: isEmpty(field2) ? item : item[field2],
			};
		})
		.filter((e) => !isEmpty(e.label));
};

export const formatTimeTo12Hour = (timeString) => {
	if (!timeString) return "-";
	return moment(timeString, "HH:mm").format("hh:mm A");
};

export const isDefined = (value) => {
	return !isEmpty(value);
};

export const toaster = {
	error: (message, config = {}) => {
		toast.error(message);
	},
	success: (message, config = {}) => {
		toast.success(message);
	},
};

export const sendHeader = (access_token) => {
	return {
		headers: {
			"Content-Type": "application/json",
			Authorization: access_token,
		},
	};
};

export const getStoreState = (key) => {
	let _store = store.getState();
	return isDefined(_store[key]) ? _store[key] : {};
};

export const isAuth = () => {
	return false == isEmpty(window.localStorage.getItem(appConfig.localStorage.token));
};

export const userRole = () => {
	return localStorage.getItem(appConfig.localStorage.role);
};

export const showErrorToaster = (err) => {
	if (err.status == 400 || err.status == 401 || err.status == 422) {
		toaster.error(err.errors.message);
	} else {
		toaster.error(err);
	}
};

export const getImageUrl = (url) => {
	const isAbsoluteUrl = /^(?:\w+:)\/\//.test(url);
	if (isAbsoluteUrl) {
		return url;
	} else {
		return `${appConfig.host}${url}`.replace(/([^:]\/)\/+/g, "$1");
	}
};

export const checkUrlType = (url) => {
	// Regular expressions to match common video and image file extensions
	const videoExtensions = /\.(mp4|mov|avi|wmv|flv|mkv)$/i;
	const imageExtensions = /\.(jpg|jpeg|png|gif|bmp)$/i;

	// Test if the URL matches the video or image extensions
	if (url.match(videoExtensions)) {
		return "video";
	} else if (url.match(imageExtensions)) {
		return "image";
	} else {
		return null;
	}
};

export const getRandomColor = () => {
	return combinationColors[Math.round(Math.random() * combinationColors.length)];
};

export const avtarDefaultNameAndColor = (name) => {
	const parts = name.split(" ");
	let code = "";

	if (Array.isArray(parts) && parts.length > 1) {
		code += parts[0].charAt(0).toUpperCase();
		code += parts[parts.length - 1].charAt(0).toUpperCase();
	} else {
		code += name.charAt(0).toUpperCase();
		code += name.charAt(1).toUpperCase();
	}

	return code;
};

export const sweetAlert = {
	delete: () => {
		return new Promise((resolve) => {
			Swal.fire({
				title: "Are you sure?",
				text: `Are you sure you want to delete this record`,
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Delete !",
				customClass: {
					container: "custom-container",
					popup: "custom-popup",
					title: "custom-title",
					htmlContainer: "custom-html",
					confirmButton: "custom-confirm",
					cancelButton: "custom-cancel",
				},
			}).then((result) => {
				if (result.isConfirmed) {
					resolve(result);
				}
			});
		});
	},

	success: (message = "Your work has been saved") => {
		Swal.fire({
			position: "top-end",
			text: message,
			showConfirmButton: false,
			width: "300px",
			timer: 1500,
		});
	},

	deleted: (message = "Record deleted") => {
		Swal.fire("Deleted!", message, "success");
	},
};

export const reArrangeArrayWithCurrentElement = (currentIndex, array) => {
	if (isEmpty(array)) {
		return [];
	}
	return [...array.slice(currentIndex, array.length), ...array.slice(0, currentIndex)];
};

export const handleError = (error) => {
	if (false == helper.isEmpty(error.errors?.message)) {
		helper.toaster.error(error.errors?.message);
	} else {
		Object.values(error.errors).map((error) => {
			helper.toaster.error(error);
		});
	}
};

export const _showErrorToaster = (err) => {
	if (err?.errors?.message?.message) {
		toaster.error(err?.errors?.message?.message);
	} else if (err?.errors?.message?.code) {
		toaster.error(err?.errors?.message?.error);
	} else if (err?.errors?.message) {
		toaster.error(err?.errors?.message);
	} else if (err?.errors) {
		if (Array.isArray(err?.errors)) {
			err?.errors.forEach((error) => {
				toaster.error(error);
			});
		} else if (typeof err.errors === "string") {
			toaster.error(err?.errors);
		} else {
			Object.values(err?.errors).forEach((error) => {
				toaster.error(error);
			});
		}
	} else {
		console.error(err);
	}
};
