import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currentPageSlug: ""
};

export const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    changeReduxState: (state, action) => {
        state = {
            ...state,
            ...action.payload
        };

        return state;
    },
  },
});

export default layoutSlice.reducer;
export const {
    changeReduxState
} = layoutSlice.actions;
