import React, { useContext } from "react";
import { Icon } from "@iconify/react";
import { Col, Row } from "react-bootstrap";
import { GeneralContext } from ".";
import { Link } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import { pageRoutes, themeConfig } from "@/configs";
import { FooterStyle } from "@assets/styles/pages/footerStyle";
import ReactImage from "@/components/ui/ReactImage";
import { Container } from "@assets/styles/common/global";

export default function Footer() {
	const { color } = useContext(GeneralContext);

	const { settings, headers: menus } = useContext(GeneralContext);
	const timeTables = useSelector((state) => state.general.timeTables);

	return (
		<FooterStyle color={color}>
			<footer className="main-footer">
				<div className="widget-section position-relative pt-5" style={{ backgroundImage: `url(${themeConfig.footerBg})` }}>
					<div className="footer-layer position-absolute top-0 start-0 w-100 h-100"></div>
					<Container className="position-relative z-3">
						<Row>
							<Col md={6} xl={3} className="footer-col mb-5 mb-xl-0 pe-md-4 pe-xl-5">
								<div className="logo position-relative mb-4">
									<ReactImage className="w-100 h-100 object-cover" src={settings?.footer_logo} image={themeConfig.FooterLogo} alt="" />
								</div>
								<div className="mb-5">
									<p className="font-14 lh-base spacing-1 text-white open-sans">
										Our Clinic has grown to provide a world class facility for the clinic advanced restorative.{" "}
									</p>
									<p className="font-14 lh-base spacing-1 text-white open-sans">
										We are among the most qualified implant providers in the AUS with over 30 years of quality training and experience.
									</p>
								</div>
								<div className="social-icons d-flex align-items-center">
									<Link to={settings?.facebook} className="text-decoration-none" target="_blank">
										<div className="social-icon me-4 d-flex align-items-center justify-content-center font-16">
											<Icon icon="ri:facebook-fill" />
										</div>
									</Link>
									<Link to={settings?.twitter} className="text-decoration-none" target="_blank">
										<div className="social-icon me-4 d-flex align-items-center justify-content-center font-16">
											<Icon icon="ri:twitter-fill" />
										</div>
									</Link>
									<Link to={settings?.youtube} className="text-decoration-none" target="_blank">
										<div className="social-icon me-4 d-flex align-items-center justify-content-center font-16">
											<Icon icon="mdi:youtube" />
										</div>
									</Link>
									<Link to={settings?.linkedin} target="_blank" className="text-decoration-none">
										<div className="social-icon me-4 d-flex align-items-center justify-content-center font-16">
											<Icon icon="ri:linkedin-fill" />
										</div>
									</Link>
								</div>
							</Col>
							<Col md={6} xl={2} className="footer-col mb-5 mb-xl-0">
								<div>
									<h2 className="footer-title position-relative font-28 lh-1 crete text-white mb-3 pb-3">Userfull</h2>
								</div>
								<ul>
									<li className="d-flex align-items-center mb-2 font-16 text-white open-sans">
										<Icon icon="ic:baseline-plus" className="me-2" />
										<Link
											className="text-white"
											to="/"
											onClick={() => {
												window.scrollTo({ top: 0, behavior: "smooth" });
											}}
										>
											Home
										</Link>
									</li>
									<li className="d-flex align-items-center mb-2 font-16 text-white open-sans">
										<Icon icon="ic:baseline-plus" className="me-2" />
										<Link
											className="text-white"
											to={pageRoutes.public.contactUs}
											onClick={() => {
												window.scrollTo({ top: 0, behavior: "smooth" });
											}}
										>
											Contact Us
										</Link>
									</li>
									<li className="d-flex align-items-center mb-2 font-16 text-white open-sans">
										<Icon icon="ic:baseline-plus" className="me-2" />
										<Link
											className="text-white"
											to={pageRoutes.public.gallery}
											onClick={() => {
												window.scrollTo({ top: 0, behavior: "smooth" });
											}}
										>
											Gallery
										</Link>
									</li>
									<li className="d-flex align-items-center mb-2 font-16 text-white open-sans">
										<Icon icon="ic:baseline-plus" className="me-2" />
										<Link
											className="text-white"
											to={pageRoutes.public.blog}
											onClick={() => {
												window.scrollTo({ top: 0, behavior: "smooth" });
											}}
										>
											Blog
										</Link>
									</li>
									<li className="d-flex align-items-center mb-2 font-16 text-white open-sans">
										<Icon icon="ic:baseline-plus" className="me-2" />
										<Link
											className="text-white"
											to={pageRoutes.public.doctor}
											onClick={() => {
												window.scrollTo({ top: 0, behavior: "smooth" });
											}}
										>
											Doctors
										</Link>
									</li>
								</ul>
							</Col>
							<Col md={6} xl={4} className="footer-col mb-5 mb-xl-0 pe-md-4 px-xl-4">
								<div>
									<h2 className="footer-title position-relative font-28 lh-1 crete text-white mb-3 pb-3">Opening Hours</h2>
								</div>
								<div className="d-flex flex-column">
									{timeTables.map((timeTable, index) => (
										<div className="time-wrapper-div d-flex align-items-center justify-content-between" key={index}>
											<span className="font-15 lh-lg text-white open-sans text-capitalize">{timeTable.day} :- </span>
											<div
												className="time-div font-15 lh-lg text-white open-sans"
												dangerouslySetInnerHTML={{
													__html: timeTable.time,
												}}
											></div>
										</div>
									))}
								</div>
							</Col>
							<Col md={6} xl={3} className="footer-col mb-5 mb-xl-0">
								<div>
									<h2 className="footer-title position-relative font-28 lh-1 crete text-white mb-3 pb-3">Contact Us</h2>
								</div>
								<ul>
									<li className="d-flex mb-4">
										<div className="icon">
											<Icon className="font-30 lh-1 text-white opacity-75 me-3" icon="carbon:location" />
										</div>
										<div className="font-16 lh-base text-white open-sans fw-normal">{settings?.address}</div>
									</li>
									<li className="d-flex mb-4">
										<div className="icon">
											<Icon className="font-30 lh-1 text-white opacity-75 me-3" icon="line-md:phone-call-loop" />
										</div>
										<div>
											<div className="font-14 lh-base text-white opacity-75 open-sans fw-normal d-block">Call us:</div>
											<Link to={`tel:${settings?.call_us}`} className="d-block font-16 lh-base text-white open-sans fw-semibold">
												{settings?.call_us}
											</Link>
										</div>
									</li>
									<li className="d-flex mb-4">
										<div className="icon">
											<Icon className="font-30 lh-1 text-white opacity-75 me-3" icon="ic:outline-email" />
										</div>
										<div>
											<div className="font-14 lh-base text-white opacity-75 open-sans fw-normal d-block">Do you have a Question?</div>
											<Link to={`mailto:${settings?.email}`} className="font-16 lh-base text-white open-sans fw-normal">
												{settings?.email}
											</Link>
										</div>
									</li>
									<li className="d-flex mb-4">
										<div className="icon">
											<Icon className="font-30 lh-1 text-white opacity-75 me-3" icon="octicon:clock-16" />
										</div>
										<div>
											<div className="font-16 lh-base text-white open-sans fw-normal">24 * 7</div>
										</div>
									</li>
								</ul>
							</Col>
						</Row>
					</Container>
					<div className="copy-right position-relative mt-5">
						<div className="bg-black opacity-25 position-absolute w-100 h-100 bottom-0"></div>
						<div className="position-relative z-3 py-3">
							<p className="font-16 lh-base res-font-14 text-white text-center open-sans fw-normal mb-0 px-5">
								Copyright © {moment().format("YYYY")} - {settings?.sidebar_name} Design by Karmadhi.
							</p>
						</div>
					</div>
				</div>
			</footer>
		</FooterStyle>
	);
}
