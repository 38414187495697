import { themeConfig } from "@/configs";
import React from "react";

const Maintenance = () => {
	return (
		<div className="d-flex align-items-center justify-content-center under--maintanance--wrapper">
			<img src={themeConfig.UnderMaintanance} alt=".." />
		</div>
	);
};

export default Maintenance;
