import React from "react";

import InitialRoutes from "./routes/InitialRoutes";

function App() {
	return (
		<>
			<InitialRoutes />
		</>
	);
}

export default App;
