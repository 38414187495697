import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	settings: {},
	headers: [],
	timeTables: [],
	paginationConfig: {},
};

export const generalSlice = createSlice({
	name: "general",
	initialState,
	reducers: {
		setSettings: (state, action) => {
			state = {
				...state,
				settings: {
					...action.payload,
				},
			};
			return state;
		},

		setHeaders: (state, action) => {
			state = {
				...state,
				headers: [...action.payload],
			};
			return state;
		},

		setTimetables: (state, action) => {
			state = {
				...state,
				timeTables: [...action.payload],
			};
			return state;
		},

		setPageNo: (state, action) => {
			state = {
				...state,
				paginationConfig: {
					...state.paginationConfig,
					...action.payload,
				},
			};

			return state;
		},

		setPaginateConfig: (state, action) => {
			state = {
				...state,
				paginationConfig: {
					...state.paginationConfig,
					...action.payload,
				},
			};

			return state;
		},

		resetPageNo: (state, action) => {
			state = {
				...state,
				paginationConfig: {},
			};

			return state;
		},
	},
});

export default generalSlice.reducer;
export const { setSettings, setHeaders, setTimetables, setPageNo, resetPageNo, setPaginateConfig } = generalSlice.actions;
