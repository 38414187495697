import { GeneralContext } from '@/pages/core/layout';
import { BackTopStyle } from '@assets/styles/ui/common/BackTopStyle';
import { Icon } from '@iconify/react';
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

const ScrollTop = () => {
    const [showScrollTopButton, setShowScrollTopButton] = useState(false);
    const { color } = useContext(GeneralContext);

    useEffect(() => {
        const onscroll = () => {
            const isReachTop = window.scrollY <= 0;
            if (isReachTop) {
                setShowScrollTopButton(false);
            } else {
                setShowScrollTopButton(true);
            }
        };
        window.addEventListener("scroll", onscroll);
        return () => {
            window.removeEventListener("scroll", onscroll);
        };
    }, []);


    return (
        <BackTopStyle color={color}>
            {showScrollTopButton && (
                <Link
                    onClick={() => {
                        window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                        });
                    }}
                    to="#!"
                    className="top--icon-wrap h-auto"
                >
                    <div className='top--icon'>
                        <Icon icon="lets-icons:arrow-top-light" />
                    </div>
                </Link>
            )}
        </BackTopStyle>
    )
}

export default ScrollTop
