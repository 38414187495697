import styled from "styled-components";

export const FooterStyle = styled.div`
  .main-footer {
    .widget-section {
      .footer-layer {
        background-color: ${(props) => props.color.backGroundColor};
        opacity: 0.95;
      }
      .footer-col {
        .social-icons {
          a {
            .social-icon {
              background-color: ${(props) => props.color.fontColor};
              color:${(props) => props.color.backGroundColor};
              width: 28px;
              height: 28px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              opacity: 0.5;
              transition: all 0.3s ease-in-out;

              &:hover {
                opacity: 1;
              }
            }
          }
        }
        .footer-title {
          &::before {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 60px;
            height: 2px;
            margin-right: 6px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            background-color: ${(props) => props.color.fontColor};
            content: "";
          }

          &::after {
            position: absolute;
            left: 65px;
            bottom: -2px;
            height: 6px;
            width: 6px;
            content: "";
            border-radius: 50%;
            background-color: ${(props) => props.color.fontColor};
          }
        }
        .logo {
          width: 220px;
          height: 70px;
        }
        .social-icons {
          .social-icon {
            color: ${(props) => props.color.fontColor};
          }
        }
      }
    }
  }
`;
