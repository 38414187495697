import { GeneralContext } from "@/pages/core/layout";
import { LoaderStyle } from "@assets/styles/ui/common/LoaderStyle";
import React, { useContext } from "react";
import { Spinner } from "react-bootstrap";

const Loader = () => {
	const { color } = useContext(GeneralContext);

	return (
		<LoaderStyle color={color}>
			<div className="loader-container">
				<div className="heading">
					<Spinner animation="grow" />
				</div>
			</div>
		</LoaderStyle>
	);
};

export default Loader;
