import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { GeneralContext } from ".";
import { CustomerPanelHeader } from "@assets/styles/ui/layout/header";
import { Icon } from "@iconify/react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { helper } from "@/services";
import { Nav, NavDropdown, Navbar } from "react-bootstrap";
import { appConfig, pageRoutes, themeConfig } from "@/configs";
import ReactImage from "@/components/ui/ReactImage";
import { isAuth } from "@/services/helper";

export default function Header() {
	const { color } = useContext(GeneralContext);
	const { settings, headers: _menus } = useContext(GeneralContext);
	const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
	const [fixedClass, setFixedClass] = useState("header-fixed-os");
	const [expanded, setExpanded] = useState(false);
	const [activeDropdown, setActiveDropdown] = useState(null);
	const dropdownRef = useRef(null);
	const location = useLocation();
	const navigate = useNavigate();

	const menus = useMemo(() => {
		return [..._menus].map((menu) => {
			let _menu = { ...menu };
			_menu.is_active = location.pathname == _menu.link;
			if (helper.isDefined(_menu?.children)) {
				_menu.children = _menu?.children.map((subMenu) => {
					let _subMenu = { ...subMenu };
					_subMenu.is_active = location.pathname == _subMenu.link;
					// if (_subMenu.is_active) {
					// 	_menu.is_active = true;`
					// }
					return _subMenu;
				});
			}
			return _menu;
		});
	}, [location.pathname, _menus]);

	useEffect(() => {
		const handleScroll = () => {
			const currentScrollPos = window.pageYOffset;
			const scrollingUp = prevScrollPos > currentScrollPos;
			const atTop = currentScrollPos === 0;

			if (atTop) {
				setFixedClass("header-fixed-os");
			} else if (scrollingUp) {
				setFixedClass("header-fixed");
			} else {
				setFixedClass("hidden");
			}

			setPrevScrollPos(currentScrollPos);
		};

		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, [prevScrollPos]);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (!event.target.closest(".header")) {
				setExpanded(false);
				setActiveDropdown(null);
			}
		};
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	useEffect(() => {
		const htmlElement = document.documentElement;

		if (expanded) {
			htmlElement.classList.add("overflow-hidden");
		} else {
			htmlElement.classList.remove("overflow-hidden");
		}

		// Cleanup function to remove the class when the component unmounts
		return () => {
			htmlElement.classList.remove("overflow-hidden");
		};
	}, [expanded]);

	const handleDropdownClick = (index) => {
		setActiveDropdown(index === activeDropdown ? null : index);
	};

	return (
		<>
			<CustomerPanelHeader color={color} opacity={0.5} className={`${true ? "" : "hidden"} ${fixedClass} ${expanded ? "header-expanded" : ""}`}>
				<section className="about-hospital py-4 d-none d-lg-flex align-items-center justify-content-between">
					<div className="d-lg-flex align-items-center ">
						<div className="hospital-icon d-flex align-items-center me-4">
							<Icon icon="ep:add-location" />
							<a href="" className="font-14 lh-1 open-sans text-black ms-2 cursor-pointer">
								{settings?.address}
							</a>
						</div>
					</div>
					<div className="social-icons d-none d-lg-flex align-items-center">
						<Link to={settings?.facebook} className="text-decoration-none" target="_blank">
							<div className="social-icon ms-3 d-flex align-items-center justify-content-center font-16">
								<Icon icon="ri:facebook-fill" />
							</div>
						</Link>
						<Link to={settings?.twitter} className="text-decoration-none" target="_blank">
							<div className="social-icon ms-3 d-flex align-items-center justify-content-center font-16">
								<Icon icon="ri:twitter-fill" />
							</div>
						</Link>
						<Link to={settings?.youtube} className="text-decoration-none" target="_blank">
							<div className="social-icon ms-3 d-flex align-items-center justify-content-center font-16">
								<Icon icon="mdi:youtube" />
							</div>
						</Link>
						<Link to={settings?.linkedin} target="_blank" className="text-decoration-none">
							<div className="social-icon ms-3 d-flex align-items-center justify-content-center font-16">
								<Icon icon="ri:linkedin-fill" />
							</div>
						</Link>
					</div>
				</section>
				<Navbar expanded={expanded} variant="dark" expand="lg" className="header">
					<Navbar.Brand href="#" className="logo text-black">
						<ReactImage
							src={settings?.logo}
							image={themeConfig.LogoImage}
							className="w-100 h-100 object-cover"
							onClick={() => {
								navigate(pageRoutes.public.home), window.scrollTo({ top: 0, behavior: "smooth" });
							}}
						/>
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")} />
					<Navbar.Collapse id="responsive-navbar-nav">
						<Nav className="ms-auto align-items-lg-center">
							{menus.map((item, index) => (
								<React.Fragment key={index}>
									{helper.isDefined(item?.children) ? (
										<NavDropdown
											title={item.title}
											id={`collasible-nav-dropdown-${index}`}
											className={`text-uppercase font-14 lh-1 open-sans fw-medium nav-link $({isActive}) => isActive ? "active-class": "non-active-class"`}
											show={activeDropdown === index}
											onClick={() => {
												handleDropdownClick(index);
											}}
											ref={dropdownRef}
										>
											{item?.children.map((children, indexItem) => (
												<NavDropdown.Item
													key={indexItem}
													className="d-flex align-items-center text-uppercase font-14 lh-1 open-sans fw-medium"
													onClick={() => {
														setExpanded(false), navigate(children.link), window.scrollTo({ top: 0, behavior: "smooth" });
													}}
												>
													{children.title}
												</NavDropdown.Item>
											))}
										</NavDropdown>
									) : (
										<NavLink
											to={item.link}
											onClick={() => {
												setExpanded(false), window.scrollTo({ top: 0, behavior: "smooth" });
											}}
											className={`text-uppercase font-14 lh-1 open-sans fw-medium nav-link $({isActive}) => isActive ? "active-class": "non-active-class" `}
										>
											{item.title}
										</NavLink>
									)}
								</React.Fragment>
							))}
							<NavDropdown
								title={`user`}
								id="collasible-nav-dropdown"
								className="py-lg-3 px-3 ms-lg-2 d-inline-flex text-uppercase font-14 lh-1 open-sans fw-medium"
								onClick={(e) => {
									e.stopPropagation();
								}}
							>
								<>
									<NavDropdown.Item href={appConfig.patient_login_url} target="_blank" className="d-flex align-items-center">
										<Icon icon="circum:login" />
										<div className="ms-2 text-uppercase font-14 lh-1 open-sans fw-medium">Login</div>
									</NavDropdown.Item>

									<NavDropdown.Item
										onClick={() => {
											(window.location.href = pageRoutes.public.register), window.scrollTo({ top: 0, behavior: "smooth" });
										}}
										className="d-flex align-items-center"
									>
										<Icon icon="mdi:register" />
										<div className="ms-2 text-uppercase font-14 lh-1 open-sans fw-medium">Register</div>
									</NavDropdown.Item>
								</>
							</NavDropdown>
						</Nav>
					</Navbar.Collapse>
				</Navbar>

				{expanded && <div className="overlay" onClick={() => setExpanded(false)}></div>}
			</CustomerPanelHeader>
		</>
	);
}
