import styled from "styled-components";

export const LoaderStyle = styled.div`
  .loader-container {
    width: 10%;
    margin: 0 auto;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "Recursive";

    .heading {
      display: flex;
      align-items: baseline;
      margin-top: 30px;

      .spinner-grow {
        background-color: ${(props) => props.color.backGroundColor};
      }
    }
  }
`;
