import appConfig from "./appConfig";

const apiConfig = {
	baseURL: (appConfig.host + "/" + appConfig.prefix + "/" + appConfig.version + "/").replace(/([^:]\/)\/+/g, "$1"),

	publicURL: (appConfig.host + "/").replace(/([^:]\/)\/+/g, "$1"),

	// auth apis
	login: "login",
	public: {
		home: {
			sliders: "home/sliders",
			sliders_single: "customer/home/sliders/:id",
			service: "home/service",
			service_single: "home/service/:id",
			choose_us: "home/choose_us",
			time_table: "/home/timetable",
			doctors: "home/doctor",
			doctor_single: "home/doctor/:id",
			testimonial: "home/testimonial",
			// testimonial: "customer/home/testimonial",
			blogs: "customer/home/blog",
			branch_time: "customer/home/branchtime",
		},
		doctors: {
			departments: "department_doctors",
			doctors: "customer/doctordepartments/doctors",
			doctor_single: "customer/doctordepartments/doctor/:id",
			doctor_time: "customer/doctordepartments/doctorstime/:id",
			get_department: "/list/department",
			get_doctor: "/home/doctor",
		},
		galleries: {
			galleries: "gallery",
			images: "customer/galleries/allimage",
		},
		pages: "page_sections",
		about_choose_use: "customer/about/aboutchooseus",
		top_service: {
			service_single: "home/service/:id",

			top_services: "home/top_service",
			top_service_single: "customer/home/service/:id",
		},
		header: "header",
		settings: "settings",
		counter: "counter",
		blog: {
			categories: "list/blog_category",
			blogs: "blogs",
			blog_single: "blogs/:id",
			blogs_latest: "customer/blogscategorie/latesblog",
		},
		branch: {
			branches: "customer/branchs",
			branch_single: "customer/branchs/:id",
			visiting: "branch/visiting",
			own: "branch/own",
			doctor: "customer/appointment/doctor",
			customer_appointment: "patient/appointment",
			delete_appointment: "patient/appointment/",
			cancel_appointment: "patient/appointment/canceled/",
			edit_appointment: "patient/appointment/",
			visiting_single: "customer/branchs/visiting/:id",
			visiting_by_doctor: "customer/branchs/visiting/doctors/:id",
			gettotalbranch: "/branch",
		},
	},
	patient: {
		list_branch: "list/branch",
		list_doctor: "/list/doctor",
		login: "patient/login",
		patientDashboard: "/patient/dashboard",
		register: "register",
		generate_otp: "/patient/otp",
		verify_otp: "/patient/verify",
		patient_profile: "/patient/profile",
		user: {
			get_users: "customer/register",
		},
		select_doctor: "patient/appointment/doctor_time",
		references: "list/branch",
		add_review: "testimonial",
		add_contact: "inquiries",
	},
};

export default apiConfig;
