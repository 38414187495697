import styled from "styled-components";

export const CustomerPanelHeader = styled.header`
  margin: 0px auto;
  position: fixed;
  width: 100%;
  z-index: 999;
  top: 0;
  transition: all 0.3s ease-in-out;

  &.header-expanded {
    height: 100vh;
    overflow: auto;
    @media (max-width: 768px) {
      height: 100%;

      .header {
        box-shadow: none;
      }
    }
  }

  &.header-fixed-os {
    top: 0px;

    @media (max-width: 768px) {
      background: var(--color-fff);
    }
  }

  &.header-fixed,
  &.hidden {
    top: -70px;

    @media (max-width: 768px) {
      top: 0px;
      background: var(--color-fff);
    }
  }

  .about-hospital {
    max-width: 1440px;
    margin: 0px auto;
    padding: 0px 80px;

    @media (max-width: 1440px) {
      margin: 0px 80px;
      padding: 18px 28px;
    }
    
    @media (max-width: 1024px) {
      margin: 0px 25px;
      padding: 0px 28px;
    }

    .hospital-icon {
      svg {
        width: 20px;
        height: 20px;

        path {
          fill: ${(props) => props.color.backGroundColor};
        }
        rect {
          fill: ${(props) => props.color.backGroundColor};
        }
      }
    }
  }

  .header {
    max-width: 1440px;
    background-color: ${(props) => props.color.fontColor};
    margin: auto;
    padding: 0px 80px;
    border-radius: 50px;
    box-shadow: 0px 0px 6px 1px #d1d1d1;
    z-index: 999;

    @media (max-width: 1440px) {
      margin: 0px 80px;
      padding: 0px 28px;
    }

    @media (max-width: 1024px) {
      margin: 0px 25px;
      padding: 0px 28px;
    }

    @media (max-width: 768px) {
      margin: 0px;
      padding: 12px 28px;
      border-radius: 0px;
      display: flex;
      align-content: flex-start;
    }

    .logo {
      width: 145px;
      height: 80px;
      padding: 18px 0px;
    }

    .navbar-toggler {
      background-color: ${(props) => props.color.backGroundColor};
      color: transparent;
      transition: all 0.5s ease-in-out;
    }
    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M3 6.001h18m-18 6h18m-18 6h18'/%3E%3C/svg%3E") !important;
      color: ${(props) => props.color.fontColor};
      width: 25px;
    }

    .navbar-collapse {
      @media (max-width: 768px) {
        padding: 30px 0px;
      }
      .navbar-nav {
        .nav-link {
          padding: 0px 15px;
          color: ${(props) => props.color.color};

          @media (max-width: 768px) {
            padding: 15px 0px;
          }

          &.active {
            color: ${(props) => props.color.backGroundColor};
          }
        }
        .dropdown {
          padding: 25px 0px;
          transition: all 0.3s ease-in-out;

          @media (max-width: 768px) {
            padding: 0px;
          }

          &.show {
            @media (max-width: 768px) {
              margin-bottom: 24px;
            }
          }
          .nav-link {
            color: ${(props) => props.color.color};

            &::after {
              transform: rotate(0deg);
            }

            &.show {
              &::after {
                transform: rotate(-180deg);
              }
            }

            @media (max-width: 768px) {
              display: block;
            }
          }
          &:last-child {
            position: relative;
            background-color: ${(props) => props.color.backGroundColor};
            border-radius: var(--radius-3);

            @media (max-width: 768px) {
              position: relative;
            }
            .nav-link {
              color: ${(props) => props.color.fontColor};
            }

            .dropdown-menu[data-bs-popper] {
              @media (max-width: 768px) {
                position: absolute;
                left: 0px;
                right: auto !important;
              }
            }
          }

          .dropdown-menu {
            border: 0;
            box-shadow: 0px 0px 5px 0px #d1d1d1;
            padding: 0px;
            overflow: hidden;

            .dropdown-item {
              padding: 12px 12px;
              &:active {
                background-color: ${(props) => props.color.backGroundColor};
              }
            }
          }
        }
      }
    }
  }
  .social-icons {
    a {
      .social-icon {
        background-color: ${(props) => props.color.backGroundColor};
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        opacity: 0.8;
        transition: all 0.3s ease-in-out;

        &:hover {
          opacity: 1;
        }
        color: ${(props) => props.color.fontColor} !important;
      }
    }
  }
`;
