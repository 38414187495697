import styled from "styled-components";

export const RegisterStyle = styled.section`
  .visitors {
    background-color: #f5f5f5 !important;
    padding: 80px 0px;
  }
  form {
    input,
    textarea {
      &::placeholder {
        color: var(--color-353f4b);
      }
      &:focus {
        outline: 0;
        box-shadow: 0 0 5px ${(props) => props.color.backGroundColor} !important;
      }
    }
  }
  .css-t3ipsp-control {
    border-color: transparent !important;
    box-shadow: 0 0 5px ${(props) => props.color.backGroundColor} !important;
    border-radius: var(--radius-8);
  }
`;
