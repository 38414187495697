const pageRoutes = {
	public: {
		login: "/login",
		home: "/",
		forgotPassword: "/forgot-password",
		register: "/register",
		about: "/about",
		services: "/services",
		servicesProfile: "/service/profile/:id",
		gallery: "/gallery",
		contactUs: "/contact-us",
		doctor: "/doctor",
		doctorDetailPage: "/doctor/profile/:id",
		blog: "/blog",
		blogDetails: "/blog/details/:id",
	},
};

export default pageRoutes;

export const filterRoute = (route) => {
	return route.substring(1);
};
