import React from "react";
import ValidationMessages from "@/components/common/validations/ValidationMessages";

const Textarea = ({
	type,
	label,
	placeholder = "",
	className = "",
	mainclassName = "",
	name,
	readonly,
	value,
	error,
	icon,
	disabled,
	id,
	onChange,
	cols,
	row = 3,
	style,
	...rest
}) => {
	return (
		<div className={`visitor--textarea ${mainclassName}`}>
			{label && (
				<label htmlFor={label} className="d-block mb-2 text-capitalize font-15 res-font-14 lh-1 open-sans text-black fw-semibold">
					{label}
				</label>
			)}

				<textarea
					{...rest}
					className={`${className}`}
					placeholder={placeholder}
					readOnly={readonly}
					disabled={disabled}
					id={id}
					cols={cols}
					row={row}
					name={name}
					onChange={onChange}
					style={style}
					value={value === (undefined || null) ? "" : value}
				></textarea>

				<ValidationMessages errors={error} label={placeholder ? placeholder : label} />
		</div>
	);
};

export default Textarea;
