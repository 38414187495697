/*==============================================
				1. Default CSS   
==============================================*/

const { default: styled } = require("styled-components");

export const Container = styled.div`
	max-width: 1440px;
	padding-left: 80px;
	padding-right: 80px;
	margin: 0 auto;

	@media (max-width: 768px) {
		padding-left: 25px;
		padding-right: 25px;
	}
`;

export const Row = styled.div`
	margin-left: 0px;
	margin-rightt: 0px;
`;

export const Divider = styled.div`
	svg {
		path {
			stroke: ${(props) => props.color.backGroundColor} !important;
		}
	}
`;

export const hexToRgba = (hex, opacity) => {
	let r = 0,
		g = 0,
		b = 0;

	// 3 digits
	if (hex?.length === 4) {
		r = parseInt(hex[1] + hex[1], 16);
		g = parseInt(hex[2] + hex[2], 16);
		b = parseInt(hex[3] + hex[3], 16);

		// 6 digits
	} else if (hex?.length === 7) {
		r = parseInt(hex[1] + hex[2], 16);
		g = parseInt(hex[3] + hex[4], 16);
		b = parseInt(hex[5] + hex[6], 16);
	}

	return `rgba(${r},${g},${b},${opacity})`;
};
