import { pageRoutes } from "@/configs";
import _ from "lodash";
import { lazy } from "react";

/* ******************************************* visitor routes import ******************************************* */

const VisitorHome = lazy(() => import("../pages/pages/home"));
const VisitorContactUs = lazy(() => import("../pages/pages/contactUs/index"));
const VisitorService = lazy(() => import("../pages/pages/services/index"));
const VisitorServiceProfile = lazy(() => import("../pages/pages/services/ServiceProfile"));
const VisitorDoctor = lazy(() => import("../pages/pages/doctors/index"));
const VisitorDoctorDetailsPage = lazy(() => import("../pages/pages/doctors/doctorDetailsPage"));
const VisitorRegistration = lazy(() => import("../pages//core/register"));
const VisitorBlogPage = lazy(() => import("../pages/pages/blog"));
const VisitorBlogDetailPage = lazy(() => import("../pages/pages/blog/blogDetails"));
const VisitorGalleryPage = lazy(() => import("../pages/pages/gallery/index"));
const VisitorAbout = lazy(() => import("../pages/pages/about/index"));

export const webRoutes = {
	visitor: [
		{
			path: pageRoutes.public.home,
			element: VisitorHome,
		},
		{
			path: pageRoutes.public.about,
			element: VisitorAbout,
		},

		{
			path: pageRoutes.public.register,
			element: VisitorRegistration,
		},
		{
			path: pageRoutes.public.contactUs,
			element: VisitorContactUs,
		},
		{
			path: pageRoutes.public.services,
			element: VisitorService,
		},
		{
			path: pageRoutes.public.doctor,
			element: VisitorDoctor,
		},
		{
			path: pageRoutes.public.doctorDetailPage,
			element: VisitorDoctorDetailsPage,
		},
		{
			path: pageRoutes.public.blog,
			element: VisitorBlogPage,
		},
		{
			path: pageRoutes.public.blogDetails,
			element: VisitorBlogDetailPage,
		},
		{
			path: pageRoutes.public.gallery,
			element: VisitorGalleryPage,
		},
		{
			path: pageRoutes.public.servicesProfile,
			element: VisitorServiceProfile,
		},
		{
			path: pageRoutes.public.about,
			element: VisitorAbout,
		},
	],
};
