import React, { useState } from "react";
import ValidationMessages from "@/components/common/validations/ValidationMessages";
import ReactButton from "./ReactButton";
import { Icon } from "@iconify/react";

const Textinput = ({
	type,
	label,
	placeholder = "",
	mainclassName = "",
	className = "",
	name,
	readonly,
	value,
	error,
	icon,
	disabled,
	onKeyPress,
	id,
	onChange,
	...rest
}) => {
	const [open, setOpen] = useState(false);
	const handleOpen = () => {
		setOpen(!open);
	};

	// Check if the type is "number" and the value is 0, then display "0"
	const displayValue = type === "number" && value === 0 ? "0" : value;

	const handleKeyPress = (e) => {
		if (type === "number" && !/^\d+$/.test(e.key)) {
			e.preventDefault();
		}
	};

	return (
		<div className={`${mainclassName} visitor--input-box position-relative`}>
			{label && (
				<label htmlFor={label} className="d-block mb-2 text-capitalize font-15 res-font-14 lh-1 open-sans text-black fw-semibold pt-2">
					{label}
				</label>
			)}
			<input
				type={type === "password" && open === true ? "text" : type}
				{...rest}
				placeholder={placeholder}
				readOnly={readonly}
				value={displayValue !== null && displayValue !== undefined ? displayValue : ""}
				disabled={disabled}
				id={id}
				onChange={onChange}
				onKeyPress={onKeyPress} // Add this line
				name={name}
				className={className}
			/>
			<ValidationMessages errors={error} label={placeholder ? placeholder : label} />

			{type === "password" && (
				<div className="position-absolute top-0 end-0 h-100 d-flex align-items-center justify-content-end z-1">
					<button size="sm" type="button" id="password-addon" className="pe-3 border-0 bg-transparent " onClick={handleOpen}>
						<Icon icon={open ? "ph:eye-duotone" : "iconamoon:eye-off-duotone"} className="d-block" />
					</button>
				</div>
			)}
		</div>
	);
};

export default Textinput;
