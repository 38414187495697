import React, { useContext } from "react";
import { Modal } from "react-bootstrap";
import { Icon } from "@iconify/react";
import ReactButton from "../ReactButton";
import { GeneralContext } from "@/pages/core/layout";
import { ModalStyle } from "@assets/styles/ui/common/ModalStyle";

const ReactModal = (props) => {
	const { color } = useContext(GeneralContext);
	const {
		size,
		title,
		headers,
		show,
		onHide,
		className,
		children,
		btnTitle,
		isLoader = false,
		footerVisibility = true,
		onSave,
		dialogClassName = "",
		...rest
	} = props;

	return (
		<ModalStyle color={color}>
			<Modal
				dialogClassName={dialogClassName}
				{...rest}
				size={size}
				centered
				show={show}
				onHide={onHide}
				className={`dynamic-visitor-modal ${className}`}
			>
				<div className={`${className === "branch-modal" ? "branch position-relative d-block h-100" : ""}`}>
					<Modal.Header className="d-flex align-items-center justify-content-between">
						<Modal.Title as="h4" id="contained-modal-title-vcenter">
							{title}
						</Modal.Title>
						<div className="d-flex align-items-center">
							{headers && <div className="visitor--">{headers}</div>}
							<ReactButton size="sm" variant="danger" className="visitor--btn-close radius-8 px-4  text-uppercase" onClick={onHide}>
								<Icon icon="ep:close-bold" className="d-block font-18" />
							</ReactButton>
						</div>
					</Modal.Header>
					<Modal.Body className="visitors">{children}</Modal.Body>

					{footerVisibility === true && (
						<Modal.Footer className="visitors">
							<ReactButton
								size="sm"
								loader={isLoader}
								className="visitor--button m-0 font-12 lh-1 spacing-12 open-sans fw-semibold px-4 text-uppercase"
								onClick={onSave}
							>
								{btnTitle}
							</ReactButton>
						</Modal.Footer>
					)}
				</div>
			</Modal>
		</ModalStyle>
	);
};

export default ReactModal;
