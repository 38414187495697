import styled from "styled-components";

export const MainButton = styled.div`
  button {
    height: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: var(--radius-5);
    background-color: ${(props) =>
    props?.backgroundcolor
      ? props?.color?.color
      : props?.color?.backGroundColor};
    color: ${(props) => props?.color?.fontColor};
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    border: 0px;
    position: relative;
    overflow: hidden;

    .btn-title {
      position: relative;
      z-index: 9;
      color: inherit;
    }

    span {
      &:not(.btn-title) {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 20%;
        height: 100%;
        border-radius: 50%;
        transform: translateY(150%);
        transition: all 500ms ease;
        background-color: ${(props) => props?.color?.fontColor};
        z-index: 0;
      }
      &:nth-child(2) {
        left: 20%;
        transition-delay: 50ms;
      }
      &:nth-child(3) {
        left: 40%;
        transition-delay: 100ms;
      }
      &:nth-child(4) {
        left: 60%;
        transition-delay: 150ms;
      }
      &:nth-child(5) {
        left: 80%;
        transition-delay: 200ms;
      }
    }

    &:hover {
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
      span {
        color: ${(props) =>
    props.backgroundcolor
      ? props.color.color
      : props.color.backGroundColor};
        &:not(.btn-title) {
          transform: translateY(0) scale(2);
        }
      }
    }
  }

  .tab-button {
    background-color: ${(props) => props.color.fontColor} !important;
    .btn-title {
      color: ${(props) => props.color.backGroundColor};
    }
    span {
      &:not(.btn-title) {
        background-color: ${(props) => props.color.backGroundColor};
      }
    }
    &:hover {
      span {
        color: ${(props) => props.color.fontColor};
      }
    }

    &.active {
      background-color: ${(props) => props.color.backGroundColor} !important;

      .btn-title {
        color: ${(props) => props.color.fontColor};
      }

      span {
        &:not(.btn-title) {
          background-color: ${(props) => props.color.fontColor};
        }
      }
      &:hover {
        span {
          color: ${(props) => props.color.backGroundColor};
        }
      }
    }
  }
`;

export const FilterButton = styled.button`
  height: 40px;

  &.active--btn {
    background-color: ${(props) => props.color.backGroundColor};
    color: ${(props) => props.color.fontColor};
  }

  &.disabled--btn {
    background-color: #ededed;
    color: ${(props) => props.color.color};
  }
`;
export const DropdownMenu = styled.ul`
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 0;
  margin: 0;
  display: ${(props) => (props.open ? "block" : "none")};
`;

export const DropdownMenuItem = styled.li`
  cursor: pointer;

  &:hover {
    background-color: var(--color-lightgray);
  }
`;
