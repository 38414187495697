import React, { Suspense, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { initialApiCall } from "@/services";
import { useSelector } from "react-redux";
import Footer from "./footer";
import ScrollTop from "@/components/ui/ScrollTop";
import { apiConfig, appConfig } from "@/configs";
import io from "socket.io-client";
import { setSettings } from "@/store/toolkit/general";
import { store } from "@/store";
import ReviewSection from "@/components/ui/ReviewSection";
import Header from "./header";
import Loader from "@/components/ui/Loader";
import { isEmpty } from "@/services/helper";

export const GeneralContext = React.createContext({});

export default function VisitorLayout() {
	const { settings, headers } = useSelector((state) => state?.general);

	useEffect(() => {
		const socket = io(apiConfig.publicURL);
		socket.emit("join", "user");
		socket.on("settings", (data) => {
			store.dispatch(setSettings(data));
		});
		return () => {
			socket.disconnect();
		};
	}, []);

	useEffect(() => {
		initialApiCall.initialApiCalls();
	}, []);

	const color = {
		backGroundColor: isEmpty(settings?.theme) ? appConfig.defaultThemeColor : settings?.theme,
		fontColor: "var(--color-fff)",
		color: "var(--color-000)",
	};

	return (
		<GeneralContext.Provider value={{ settings: settings, headers: headers, color: color }} className="position-relative overflow-hidden">
			<Header />
			<Suspense fallback={<Loader />}>
				<Outlet />
			</Suspense>
			<ReviewSection />
			<Footer />
			<ScrollTop />
		</GeneralContext.Provider>
	);
}
