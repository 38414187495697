import { pageRoutes } from "@/configs";
import { helper } from "@/services";

export const breadCrumbs = {};
export const combinationColors = [
	{
		backgroundColor: "#17CB83",
		color: "#000000",
	},
	{
		backgroundColor: "#6ED04F",
		color: "#000000",
	},
	{
		backgroundColor: "#6BE6D3",
		color: "#000000",
	},
	{
		backgroundColor: "#9ADFE2",
		color: "#000000",
	},
	{
		backgroundColor: "#3C7A12",
		color: "#ffffff",
	},
	{
		backgroundColor: "#938FB3",
		color: "#000000",
	},
	{
		backgroundColor: "#A94927",
		color: "#ffffff",
	},
	{
		backgroundColor: "#854028",
		color: "#ffffff",
	},
	{
		backgroundColor: "#569C66",
		color: "#000000",
	},
	{
		backgroundColor: "#4E92CA",
		color: "#000000",
	},
	{
		backgroundColor: "#3AE6BD",
		color: "#000000",
	},
	{
		backgroundColor: "#67BAAB",
		color: "#000000",
	},
	{
		backgroundColor: "#9CB8AA",
		color: "#000000",
	},
	{
		backgroundColor: "#91B7B9",
		color: "#000000",
	},
	{
		backgroundColor: "#F2D1FE",
		color: "#000000",
	},
	{
		backgroundColor: "#DDF01F",
		color: "#000000",
	},
	{
		backgroundColor: "#1BAF17",
		color: "#ffffff",
	},
	{
		backgroundColor: "#4ACF4C",
		color: "#000000",
	},
	{
		backgroundColor: "#41F5F5",
		color: "#000000",
	},
	{
		backgroundColor: "#ECEB22",
		color: "#000000",
	},
	{
		backgroundColor: "#F2456B",
		color: "#ffffff",
	},
	{
		backgroundColor: "#35C631",
		color: "#000000",
	},
	{
		backgroundColor: "#8EEA49",
		color: "#000000",
	},
	{
		backgroundColor: "#689A73",
		color: "#000000",
	},
	{
		backgroundColor: "#53F521",
		color: "#000000",
	},
	{
		backgroundColor: "#EFC68A",
		color: "#000000",
	},
	{
		backgroundColor: "#825CAC",
		color: "#ffffff",
	},
	{
		backgroundColor: "#1C37CC",
		color: "#ffffff",
	},
	{
		backgroundColor: "#34582A",
		color: "#ffffff",
	},
	{
		backgroundColor: "#9B853B",
		color: "#000000",
	},
	{
		backgroundColor: "#024110",
		color: "#ffffff",
	},
	{
		backgroundColor: "#C16A94",
		color: "#000000",
	},
	{
		backgroundColor: "#144987",
		color: "#ffffff",
	},
	{
		backgroundColor: "#9B5C25",
		color: "#ffffff",
	},
	{
		backgroundColor: "#3C7D41",
		color: "#ffffff",
	},
	{
		backgroundColor: "#16A52C",
		color: "#ffffff",
	},
];

export const validationMessages = {
	accepted: ":attribute must be accepted.",
	after: ":attribute must be after :after.",
	after_or_equal: ":attribute must be equal or after :after_or_equal.",
	alpha: ":attribute must contain only alphabetic characters.",
	alpha_dash: ":attribute may only contain alpha-numeric characters, as well as dashes and underscores.",
	alpha_num: ":attribute must be alphanumeric.",
	before: ":attribute must be before :before.",
	before_or_equal: ":attribute must be equal or before :before_or_equal.",
	between: {
		numeric: ":attribute must be between :min and :max.",
		string: ":attribute must be between :min and :max characters.",
	},
	confirmed: ":attribute confirmation does not match.",
	email: ":attribute format is invalid.",
	date: ":attribute is not a valid date format.",
	def: ":attribute attribute has errors.",
	digits: ":attribute must be :digits digits.",
	digits_between: ":attribute must be between :min and :max digits.",
	different: ":attribute and :different must be different.",
	in: "selected :attribute is invalid.",
	integer: ":attribute must be an integer.",
	hex: ":attribute should have hexadecimal format",
	min: {
		numeric: ":attribute must be at least :min.",
		string: ":attribute must be at least :min characters.",
	},
	max: {
		numeric: ":attribute may not be greater than :max.",
		string: ":attribute may not be greater than :max characters.",
	},
	not_in: "selected :attribute is invalid.",
	numeric: ":attribute must be a number.",
	present: ":attribute must be present (but can be empty).",
	required: ":attribute is required.",
	required_if: ":attribute is required when :other is :value.",
	required_unless: ":attribute is required when :other is not :value.",
	required_with: ":attribute is required when :field is not empty.",
	required_with_all: ":attribute is required when :fields are not empty.",
	required_without: ":attribute is required when :field is empty.",
	required_without_all: ":attribute is required when :fields are empty.",
	same: ":attribute and :same fields must match.",
	size: {
		numeric: ":attribute must be :size.",
		string: ":attribute must be :size characters.",
	},
	string: ":attribute must be a string.",
	url: ":attribute format is invalid.",
	regex: ":attribute format is invalid.",
	attributes: {},
};


